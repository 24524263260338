import axios from "axios";

const API_URL = `${process.env.VUE_APP_BASE_API_URL}/clients`;
class ClientsService {
  static getAuthHeader() {
    const token = localStorage.getItem("access_token");
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  }


  static async getClients() {
    try {
      const response = await axios.get(API_URL, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching clients requests:", error);
      throw error;
    }
  }


  static async getClientsById(id) {
    try {
      const response = await axios.get(`${API_URL}/${id}`, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(`Error fetching clients request with ID ${id}:`, error);
      throw error;
    }
  }


  static async createClient(clientData) {
    try {
      const response = await axios.post(API_URL, clientData, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error("Error creating client request:", error);
      throw error;
    }
  }


  static async updateClient(id, updatedData) {
    try {
      const response = await axios.put(`${API_URL}/${id}`, updatedData, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(`Error updating client request with ID ${id}:`, error);
      throw error;
    }
  }


  static async deleteClient(id) {
    try {
      const response = await axios.delete(`${API_URL}/${id}`, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(`Error deleting client request with ID ${id}:`, error);
      throw error;
    }
  }
}

export default ClientsService;
