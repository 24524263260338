<template>
    <div class="flex"> <!-- Sidebar Container -->
        <aside class="bg-gray-800 text-white transition-all duration-300 ease-in-out"> <!-- Sidebar -->
            <!-- Sidebar Content -->
            <div class="p-4"> 
                <ul class="space-y-4"> 
                    <li v-for="(item, index) in menuItems" :key="index"> <!-- Loop through menu items -->
                        <router-link
                            :to="item.path"  
                            class="hover:bg-gray-700 p-2 rounded block transition-colors duration-200">
                            {{ item.label }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </aside>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    props: {
        menuItems: {
            type: Array,
            required: true,
            default: () => []
        }
    }
}
</script>

<style scoped>
aside {
    height: 100vh;
    position: sticky;
    top: 0;
    width: 16rem;
}

@media (max-width: 768px) {
    aside {
        width: auto;
        min-width: 12rem;
    }
}

@media (max-width: 640px) {
    aside {
        min-width: 10rem;
    }
}
</style>
