import { createRouter, createWebHistory } from "vue-router";
import NewJopRequest from "./views/mts/NewJopRequest.vue";
import { authGuard } from './middleware/auth';

const MainLayout = () => import("./components/layouts/MainLayout.vue");
const AuthLayout = () => import("./components/layouts/AuthLayout.vue");
const HomePage = () => import("./views/user/HomePage.vue");
const ProjectsPage = () => import("./views/project/ProjectsPage.vue");
const MtsPage = () => import("./views/mts/MtsPage.vue");
const AddProjectForm = () => import("./components/forms/AddProjectForm.vue");
const ProjectDetailsPage = () =>
  import("./views/project/ProjectDetailsPage.vue");
const Backlog = () => import("./views/project/Backlog.vue");
const Info = () => import("./views/project/Info.vue");
const Members = () => import("./views/project/Members.vue");
const Board = () => import("./views/project/Board.vue");
const Sprints = () => import("./views/project/sprint/Sprints.vue");
const SprintDetails = () => import("./views/project/sprint/SprintDetails.vue");
const LoginPage = () => import("./views/auth/LoginPage.vue");
const RegisterPage = () => import("./views/auth/RegisterPage.vue");
const PersonalRegisterPage = () =>
  import("./views/auth/PersonalRegisterPage.vue");
const CompanyRegisterPage = () =>
  import("./views/auth/CompanyRegisterPage.vue");
const LandingPage = () => import("./views/LandingPage.vue");
const ViewTasks = () => import("./views/user/ViewTasks.vue");
const ForgotPasswordPage = () => import("./views/auth/ForgotPasswordPage.vue");
const ResetPasswordPage = () => import("./views/auth/ResetPasswordPage.vue");
const InviteEmployeePage = () => import("./views/auth/InviteEmployeePage.vue");
const OTPVerificationPage = () =>
  import("./views/auth/OTPVerificationPage.vue");
const TeamsPage = () => import("./views/user/TeamsPage.vue");
const PermissionsPage = () => import("./views/user/PermissionsPage.vue");
const Employees = () => import("./views/user/Employees.vue");
const handleProfile = () => import("./views/user/ProfileAndVisibility.vue");
const RolePage = () => import("./views/role/RolePage.vue");
const ModulePage = () => import("./views/role/ModulePage.vue");
const ActionPage = () => import("./views/role/ActionPage.vue");
const AddClient = () => import("./views/mts/AddClient.vue");
const CompaniesProfiles = () => import("./views/mts/CompaniesProfiles.vue");
const routes = [
  {
    path: "/",
    component: LandingPage,
    meta: { breadcrumb: "Landing" },
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "home",
        component: HomePage,
        meta: { breadcrumb: "Home", requiresAuth: true },
      },
      {
        path: "profile",
        component: handleProfile,
        meta: { breadcrumb: "Profile", requiresAuth: true },
      },
      {
        path: "projects",
        component: ProjectsPage,
        meta: { breadcrumb: "Projects", requiresAuth: true },
      },
      {
        path: "mts",
        component: MtsPage,
        meta: { breadcrumb: "mts", requiresAuth: true },
      },
      {
        path: "mts/clients",
        component: CompaniesProfiles,
        meta: { breadcrumb: "Clients", requiresAuth: true
         },
      },
      {
        path: "mts/new-jop-request",
        name: "NewJopRequest",
        component: NewJopRequest,
        meta: {
          breadcrumb: "New Jop Request",
          requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "mts/add-client",
        component: AddClient,
        meta: {
          breadcrumb: "Add Client",
          requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "projects/new-project",
        name: "NewProject",
        component: AddProjectForm,
        meta: {
          breadcrumb: "New Project",
          requiresAuth: true,
          roles: ["super-admin"],
        },
      },

      {
        path: "projects/:id",
        component: ProjectDetailsPage,
        name: "ProjectDetailsPage",
        props: true,
        meta: { breadcrumb: "Project Details", requiresAuth: true },
        redirect: { name: "ProjectBoard" },
        children: [
          {
            path: "info",
            component: Info,
            name: "ProjectInfo",
            meta: { breadcrumb: "Info" },
          },
          {
            path: "members",
            component: Members,
            name: "ProjectMembers",
            meta: { breadcrumb: "Members" },
          },
          {
            path: "backlog",
            component: Backlog,
            name: "ProjectBacklog",
            meta: { breadcrumb: "Backlog" },
          },
          {
            path: "board",
            component: Board,
            name: "ProjectBoard",
            meta: { breadcrumb: "Board" },
          },
          {
            path: "sprints",
            component: Sprints,
            name: "ProjectSprints",
            meta: { breadcrumb: "Sprints" },
          },
          {
            path: "sprints/:sprintId",
            name: "SprintDetails",
            component: SprintDetails,
            meta: { breadcrumb: "Sprint Details" },
          },
        ],
      },
      {
        path: "teams",
        component: TeamsPage,
        meta: {
          breadcrumb: "Teams",
          requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "permissions",
        component: PermissionsPage,
        meta: {
          breadcrumb: "Permissions",
          requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "tasks",
        component: ViewTasks,
        meta: { breadcrumb: "Tasks", requiresAuth: true },
      },
      {
        path: "employees",
        component: Employees,
        meta: {
          breadcrumb: "Employees",
          requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "role",
        component: RolePage,
        meta: {
          breadcrumb: "Role",
          // requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "role/:id/module",
        component: ModulePage,
        meta: {
          breadcrumb: "Role Modules",
          // requiresAuth: true,
          roles: ["super-admin"],
        },
      },
      {
        path: "/role/:id/:slug/actions",
        component: ActionPage,
        meta: {
          breadcrumb: "Action",
          // requiresAuth: true,
          roles: ["super-admin"],
        },
      },
    ],
  },
  {
    path: "/login",
    component: AuthLayout,
    children: [
      { path: "", component: LoginPage, meta: { breadcrumb: "Login" } },
    ],
  },
  {
    path: "/register",
    component: AuthLayout,
    children: [
      { path: "", component: RegisterPage, meta: { breadcrumb: "Register" } },
      {
        path: "personal",
        component: PersonalRegisterPage,
        meta: { breadcrumb: "Register" },
      },
      {
        path: "company",
        component: CompanyRegisterPage,
        meta: { breadcrumb: "Register" },
      },
    ],
  },
  {
    path: "/verify",
    component: AuthLayout,
    children: [
      {
        path: "",
        component: OTPVerificationPage,
        meta: { breadcrumb: "Verify OTP" },
      },
    ],
  },
  {
    path: "/forgotpassword",
    component: AuthLayout,
    children: [
      {
        path: "",
        component: ForgotPasswordPage,
        meta: { breadcrumb: "Forgot Password" },
      },
    ],
  },
  {
    path: "/resetpassword/:token",
    component: AuthLayout,
    children: [
      {
        path: "",
        component: ResetPasswordPage,
        meta: { breadcrumb: "Reset Password" },
      },
    ],
  },
  {
    path: "/inviteemployee/:token",
    component: AuthLayout,
    children: [
      {
        path: "",
        component: InviteEmployeePage,
        meta: { breadcrumb: "Invite Employee" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(authGuard);

export default router;
